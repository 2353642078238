<div class="c-login-user">
  <div class="c-login-user__unlogged" *ngIf="!loginDisplay && !isLanding">
      <button type="button" pButton pRipple (click)="login()" label="{{'login' | translate }}" class="p-button--primary p-button-success mb-2"></button>
  </div>
  <div class="c-login-user__logged" *ngIf="loginDisplay && !isLanding">
      <p-tieredMenu class="c-login-user__menu" [viewportHeight]="150" #menu [model]="items" [popup]="true"></p-tieredMenu>
      <p class="c-login-user__name" (click)="menu.toggle($event)">
        <span class="pi pi-user"></span>
        <span class="d-none d-sm-inline">
          {{userName}}
        </span>
        <span class="pi pi-chevron-down"></span>
      </p>

    </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'resolveStringObject' })
export class ResolveStringObjectPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!value) {
      return value;
    }
    return args.split('.').reduce((p: { [x: string]: any; } | undefined, c: string | number) => p !== undefined && p[ c ], value);
  }
}

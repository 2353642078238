import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'positiveSymbol'
})
export class PositiveSymbolPipe implements PipeTransform {

  transform(val: number): string {
    if (val !== undefined && val !== null) {
      if (val > 0) {
        return `+${val}`;
      }
      return val.toString();
    }
    return "";
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent implements OnInit, OnDestroy {

  items!: MenuItem[];
  loggedIn: boolean = false;
  userName!: any;
  loginDisplay = false;
  isLanding: boolean = false;
  suplantationDisplay: boolean = false;
  suplID: any;
  suplAux: any;
  suplShow: boolean = false;
  // suplString: string = "";

  private readonly _destroying$ = new Subject<void>()

  constructor(public translate: TranslateService,
              public msalService: MsalService,
              public broadcastService: MsalBroadcastService,
              public route: Router,
              @Inject(MSAL_GUARD_CONFIG) public msalGuardConfig: MsalGuardConfiguration) { }

  ngOnInit(): void {
    // setTimeout(()=>{
    //   location.pathname === '/landing' ? this.isLanding = true : this.isLanding = false;
    // },50);

    // this.broadcastService.inProgress$
    // .pipe(
    //   filter((status: InteractionStatus) => status === InteractionStatus.None),
    //   takeUntil(this._destroying$)
    //   )
    //   .subscribe(() => {
    //     this.isLanding = false;
    //     this.setLoginDisplay();
    // })

    // this.translate.stream('header').subscribe((res: any) => {
    //   this.items = [
    //     {
    //       label: res['logout'],
    //       icon: 'pi pi-power-off',
    //       command: (_event) => {
    //         this.logout();
    //       }
    //     }
    //   ];
    // })
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() !== null;
  }

  login() {
    // location.pathname === '/landing' ? this.isLanding = true : this.isLanding = false;
    // if(!localStorage.getItem('suplantation')) {
    //   localStorage.setItem('suplantation', '{"suplantation": {"status": "false","id": ""}}');
    // }
    // this.suplAux = JSON.parse(localStorage.getItem('suplantation')!);

    // // change menu desplegable nombre suplantacion
    // this.changeItemMenu();

    // if(!this.isLoggedIn()){
    //   this.msalService.loginPopup().subscribe({
    //     next: (response: AuthenticationResult) => {
    //       this.msalService.instance.setActiveAccount(response.account)
    //       this.userName = response.account?.name;
    //       this.loggedIn = true;
    //       if(!this.loginDisplay){
    //         this.route.navigate(['/landing']);
    //       }
    //     },
    //     error: (error) => {
    //       location.pathname === '/landing' ? this.isLanding = true : this.isLanding = false;
    //       this.route.navigate(['/landing']);
    //     }
    //   });
    // }
  }

  setLoginDisplay() {
    // this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    // if(this.msalService.instance.getAllAccounts().length != 0){
    //   this.userName = this.msalService.instance.getAllAccounts()[0].name;
    //   this.loggedIn = true;
    // }
  }

  logout() {
    this.isLanding = true;
    this.loggedIn = false;
    this.msalService.logout();
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  suplantationOn() {
    this.suplantationDisplay = true;
  }

  suplantationOff() {
    localStorage.setItem('suplantation', '{"suplantation": {"status": "false","id": ""}}');
    this.suplAux = JSON.parse(localStorage.getItem('suplantation')!)

    this.suplantationDisplay = false;
    this.suplShow = false;

    this.changeItemMenu();

    // window.location.reload();
  }

  sendSuplantation(param: any) {
    localStorage.setItem('suplantation', `{"status": "true","id": "${param}"}`);
    this.suplAux = JSON.parse(localStorage.getItem('suplantation')!);

    this.suplantationDisplay = false;
    this.suplShow = true;

    this.changeItemMenu();

    // window.location.reload();
  }

  changeItemMenu() {
    console.log('this.suplAux: ', this.suplAux)
    this.translate.stream('header').subscribe((res: any) => {
      if(this.suplAux.status === 'true') {
        this.items = [
          {
            label: res['logout'],
            icon: 'pi pi-power-off',
            command: (_event) => {
              this.logout();
            }
          },
          {
            separator: true
          },
          {
            label: res['noSuplantation'],
            icon: 'pi pi-times',
            command: (_event) => {
              this.suplantationOff();
            }
          }
        ];
      } else {
        this.items = [
          {
            label: res['logout'],
            icon: 'pi pi-power-off',
            command: (_event) => {
              this.logout();
            }
          },
          {
            separator: true
          },
          {
            label: res['suplantation'],
            icon: 'pi pi-user-edit',
            command: (_event) => {
              this.suplantationOn();
            }
          }
        ];
      }
    })
    console.log('this.items: ', this.items)

  }



}

import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from '@app/services/shared/device.service';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject, firstValueFrom } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {

  constructor(public msalService: MsalService,
              public translate: TranslateService,
              public broadcastService: MsalBroadcastService,
              public route: Router,
              @Inject(MSAL_GUARD_CONFIG) public msalGuardConfig: MsalGuardConfiguration) { }

  loginLanding() {
    this.route.navigate(['/home']);
  }
}

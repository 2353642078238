import { Pipe, PipeTransform } from '@angular/core';

// divide un numero por otro
@Pipe({ name: 'divideNumber' })
export class DivideNumberPipe implements PipeTransform {
  transform(value: number, args: number): any {

    return !isNaN(value) && isFinite(value) ? value / args : value;
  }
}

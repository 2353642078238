<div class="c-landing">
    <div class="c-landing__banner slide-in-blurred-top">
        <div class="row">
            <div class="col-md-12">
                <p class="c-landing__banner-logo">
                    <img src="../../../assets/images/Logo Prosegur_negro_RGB.svg" alt="logo">
                </p>
                <h1>{{'title' | translate }}</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <h2 class="c-landing__banner-hi slide-in-blurred-left">{{'landing.hi' | translate }}</h2>
                <p class="c-landing__banner-info slide-in-blurred-left">
                    {{'landing.info' | translate }}
                </p>
            </div>
            <div class="col-md-6">
                <div class="c-landing__user-unlogged slide-in-blurred-right">
                    <button type="button" pButton pRipple (click)="loginLanding()" label="{{'landing.btnLogin' | translate }}" class="p-button--primary p-button-success mb-2"></button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="c-header">
    <div class="container-max">
        <div class="container-90-per h-100 d-flex flex-row flex-nowrap">
            <!-- <div class="col-md-1"></div> -->
            <div class="align-self-start me-auto c-header__logo d-flex align-items-center">
                <a routerLink="/">
                    <img class="my-auto" src="./assets/images/prosegur.png" alt="Prosegur">
                </a>
            </div>
            <p-menu #langMenu  [popup]="true" [model]="langItemsDropdown" appendTo="body" [baseZIndex]="9999" [hide]></p-menu>
            <button
                pButton
                class="align-self-center p-button--primary--hover-none"
                type="button"
                (click)="langMenu.toggle($event)">
                <span class="p-button-icon p-button-icon-left pi pi-globe" aria-hidden="true"></span>
                <span class="d-none d-sm-inline">{{ 'shared.currentLang' | translate  }}</span>
            </button>
            <ng-container *ngIf="!isLanding">
                <p-menu #userMenu [popup]="true" [model]="userItemsDropdown"  appendTo="body" class="z-3" [baseZIndex]="9999"></p-menu>
                <button
                    pButton
                    class="align-self-center p-button--primary--hover-none"
                    type="button"
                    (click)="userMenu.toggle($event)">
                    <span class="p-button-icon p-button-icon-left pi pi-user" aria-hidden="true"></span>
                    <span class="d-none d-sm-inline">{{userName}}</span>
                </button>
            </ng-container>
        </div>
    </div>
</div>


import { constant } from "@env/enviroments.constant";

export const environment = {
  ...constant,
  production: true,
  envName: 'pro',
  serverUrl: 'https://htr-documents.prosegur.com',
  tenantId: '68485601-fbbc-47c6-b156-3e1a7e0a4434',
  apiServerUrl:
    'https://htr-api.prosegur.com/documents-api/integration-documents-api/v1',
  apiClientId: '88761acc-1abd-4f19-8e1b-fc2f037dbb60',
  clientId: 'a74da9a0-7ef2-4300-b0c5-be109fdc781e',
};

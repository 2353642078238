import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'calendarLanguage' })
export class CalendarLanguagePipe implements PipeTransform {
  transform(value: string, args: string): any {
    if (value === 'es') {
      return '';
    }
  }
}

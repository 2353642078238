import { Component, OnInit, ViewChild, Input, Output, Inject, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Menu, MenuModule } from 'primeng/menu';
import { filter, Subject, takeUntil, map } from 'rxjs';

interface Language {
  name: string,
  code: string
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private readonly _destroying$ = new Subject<void>()

  @ViewChild("langMenu", { static: false }) langMenu!: Menu;
  @ViewChild("userMenu", { static: false }) userMenu!: Menu;

  @Input() isLanding: boolean = true;

  items!: MenuItem[];

  languages!: Language[];

  langItemsDropdown: any = [
    {
      label: 'Español',
      command: () => {
        this.switchLang({ name: "Español", code: "es" })
      }
    },
    {
      label: 'English',
      command: () => {
        this.switchLang({ name: "English", code: "en" })
      }
    },
    {
      label: 'Português',
      command: () => {
        this.switchLang({ name: "Português", code: "pl" })
      }
    }
  ];

  selectedLangVar!: Language;

  userItemsDropdown!: MenuItem[];
  loggedIn: boolean = false;
  userName!: any;
  currentLang$ = this.translate.onLangChange.pipe(map(e => e.lang));
  loginDisplay = false;

  constructor(public translate: TranslateService,
    public router: Router,
    public msalService: MsalService,) { }

  ngOnInit() {
    this.languages = [
      { name: "Español", code: "es" },
      { name: "English", code: "en" },
      { name: "Português", code: "pl" }
    ];
    this.translate.addLangs(['es', 'en', 'pl']);
    if (localStorage.getItem('lang')) {
      this.translate.setDefaultLang(localStorage.getItem('lang')!);
      if (localStorage.getItem('lang') === 'es') {
        this.selectedLangVar = {
          name: "Español",
          code: localStorage.getItem('lang')!.toString(),
        };
      } else if (localStorage.getItem('lang') === 'en') {
        this.selectedLangVar = {
          name: "English",
          code: localStorage.getItem('lang')!.toString(),
        };
      } else {
        this.selectedLangVar = {
          name: "Português",
          code: localStorage.getItem('lang')!.toString(),
        };
      }

      this.switchLang(this.selectedLangVar);

    } else {
      this.translate.setDefaultLang('es');
      this.selectedLangVar = {
        name: "Español",
        code: "es"
      };
    }

    this.translate.stream('header').subscribe((res: any) => {
      this.userItemsDropdown = [
        {
          label: res['logout'],
          icon: 'pi pi-power-off',
          command: (_event) => {
            this.logout();
          }
        }
      ];
    })
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(event => {
      this.isLanding = location.pathname === '/landing';
    });
  }

  switchLang(lang: any) {
    this.selectedLangVar = {
      name: lang.name,
      code: lang.code
    }
    localStorage.setItem('lang', lang.code);
    this.translate.use(lang.code);
    this.translate.currentLang = lang.code;
  }

  logout() {
    this.isLanding = true;
    this.loggedIn = false;
    this.msalService.logout();
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll() {
    this.userMenu.hide();
    this.langMenu.hide();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { NoAuthGuard } from '@app/guards/no-auth.guard';

import { DocumentsListComponent } from '@pages/documents-list/documents-list.component';
import { LandingComponent } from '@pages/landing/landing.component';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  {
      path: 'landing',
      component: LandingComponent,
      canActivate: [NoAuthGuard]
  },
  {
    path: 'home',
    component: DocumentsListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled',
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { DeviceService } from '@app/services/shared/device.service';
import { MsalService } from '@azure/msal-angular';
import { Observable, Subject, map, tap, lastValueFrom, firstValueFrom, BehaviorSubject, switchMap, shareReplay, ReplaySubject, catchError } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { DocumentProcessedResponse, DocumentResponse } from '@schemas/document.interface';
import { Base64Document } from '@schemas/base64Document.interface';
import { IpService } from '../shared/ip.service';
import PQueue from 'p-queue';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  //#region constructor and properties
  private queue = new PQueue({concurrency: 1});
  private isLoading: Subject<boolean> = new Subject();
  private events: Subject<any> = new Subject();
  public readonly events$ = this.events.asObservable();
  public readonly isLoading$ = this.isLoading.asObservable();
  private readonly displayedDocument: BehaviorSubject<any | null> =  new BehaviorSubject(null);
  public get displayedDocument$() { return this.displayedDocument.asObservable(); }
  private readonly menuActions = {
    '1': {
      id: 1,
      label: '',
      i18text: 'actionButtons.download',
      icon: 'pi pi-download',
      visible: true,
    },
    '2': {
      id: 2,
      label: 'Ver',
      i18text: 'actionButtons.view',
      icon: 'pi pi-eye',
      visible: true,
    },
    '3': {
      id: 3,
      label: 'Conforme',
      i18text: 'actionButtons.view',
      icon: 'pi pi-eye',
      visible: false,
    },
  };
  private eventMessages = {
    "actions.-1": {
      "success": {
          "severity": 'success',
          "title": "Éxito",
          "detail": "Se ha descargado el archivo"
        },
      "error": {
        "severity": 'error',
        "title": "Problema!!!",
        "detail": "No se han podido descargar los documentos. Por favor inténtelo más tarde."
      }
    },
    "actions.1": {
      "success": {
          "severity": 'success',
          "title": "Éxito",
          "detail": "Se ha descargado el documento"
        },
      "error": {
        "severity": 'error',
        "title": "Problema!!!",
        "detail": "No se ha podido descargar el documento. Por favor inténtelo más tarde."
      }
    },
    "actions.2": {
      "success": {
          "severity": 'success',
          "title": "Éxito",
          "detail": "Se ha abierto el documento"
        },
      "error": {
        "severity": 'error',
        "title": "Problema!!!",
        "detail": "No se ha podido abrir el documento. Por favor inténtelo más tarde."
      }
    },
    "actions.3": {
      "success": {
          "severity": 'success',
          "title": "Éxito",
          "detail": "Se ha actualizado la conformidad del documento"
        },
      "error": {
        "severity": 'error',
        "title": "Problema!!!",
        "detail": "No se ha podido establecer la conformidad del documento. Por favor inténtelo más tarde."
      }
    }
  };
  //#endregion

  constructor(
    private http: HttpClient,
    private msalService: MsalService,
    private ipService: IpService,
    private deviceService: DeviceService,
    public messageService: MessageService,
    private translate: TranslateService) {
      this.translate.onLangChange.subscribe(() => {
        Object.keys(this.eventMessages).forEach(it18key => {
            firstValueFrom(this.translate.stream(it18key)).then(
              (action: any) => {
                (this.eventMessages as any)[it18key].success.summary = action.event.success.title;
                (this.eventMessages as any)[it18key].success.detail = action.event.success.detail;
                (this.eventMessages as any)[it18key].error.summary = action.event.error.title;
                (this.eventMessages as any)[it18key].error.detail = action.event.error.detail;
              }
            );
        });
      });
    this.translate.stream('actionButtons').subscribe(action => {
      this.menuActions['1'].label = action.download;
      this.menuActions['2'].label = action.view;
    });
  }

  private getEmployeeId(): string {
    return this.msalService.instance.getActiveAccount()!.username.split('@', 1).shift()??'';
  }


  public dispatchAction(actionId: number,  ...args: any[]) {
    let promise = Promise.resolve();
    this.isLoading.next(true);
    if (actionId ==  1) promise = this.downloadSingleDocument(args[0]);
    if (actionId ==  2) promise = this.previewDocument(args[0]);
    if (actionId ==  3) promise = this.setConform(args[0], args[1]);
    if (actionId == -1) promise = this.downloadMultipleDocuments(args);
    return promise.then((event)=> {
        this.isLoading.next(false);
        this.events.next(event)
        this.getLoggedInEmployeeDocuments()
      }).catch((event) => {
        this.isLoading.next(false);
        this.events.next(event);
      });
  }


  getLoggedInEmployeeDocuments(): Observable<DocumentProcessedResponse[]> {
    this.isLoading.next(true);
    this.documents$ = this.http.get<DocumentResponse[]>(`${environment.apiServerUrl}/documents`).pipe(
      switchMap((documents) => this.translate.stream('actionButtons').pipe(map(actionButtons => ({actionButtons, documents})))
    ))
    .pipe(map(({documents, actionButtons}) =>{
      const documentsProceded = (documents as unknown as DocumentProcessedResponse[]);
      documentsProceded.forEach((document) => {
        document.creationDate = new Date(document.creationDate as unknown as string);
        document.creationDateAsFormatString = document.creationDate.toLocaleDateString();
        document.creationDate = new Date(document.creationDate);
        document.menuActions ||= [];
        document.menuActions.length = 0;
        document.creationMonthNumber = document.creationDate.getMonth() + 1;
        document.creationYear = document.creationDate.getFullYear();
        document.creationMonthAndYear = `${document.creationYear}-${document.creationMonthNumber < 10 ? '0' : ''}${ document.creationMonthNumber}`
        if(document.actions.download.status !== 'unavailable')
          document.menuActions.push({
            label: actionButtons.download,
            icon: 'pi pi-download',
            command: () => this.dispatchAction(document.actions.download.id, document)
          });

        if(document.actions.preview.status !== 'unavailable')
          document.menuActions.push({
            label: actionButtons.view,
            icon: 'pi pi-eye',
            command: () => this.dispatchAction(document.actions.preview.id, document)
          });
        return document;
      })
      documentsProceded.sort((p, n) => p.creationMonthAndYear > n.creationMonthAndYear ? -1 : 1);
      this.isLoading.next(false);
      return documents as unknown as DocumentProcessedResponse[];
    }), catchError(err => {
          gtag('event', 'error_javascript', {
            employee_id: this.msalService.instance
                .getActiveAccount()!
                .username.split('@', 1)
                .shift() ?? '',
             message: `${err.error.status}|${err.error.message}|${err.error.error}`
            });
      this.isLoading.next(false);
      return [];
    }));
    return this.documents$;
  }

  private documentsSubject$: Subject<Observable<DocumentProcessedResponse[]>> = new ReplaySubject();

  get documents$(): Observable<DocumentProcessedResponse[]> {
    return this.documentsSubject$.asObservable().pipe(switchMap((obs$) => obs$));
  }

  private set documents$(obs$: Observable<DocumentProcessedResponse[]>) {
    this.documentsSubject$.next(obs$);
  }

  private downloadMultipleDocuments(ids: number[]): Promise<any> {
    return lastValueFrom(this.http.get(`${environment.apiServerUrl}/documentsZip?isAvailable=true&${ids.map(id => `id=${id}`).join('&')}`, {
      observe: 'response',
      responseType: 'blob'
    })).then(async (res: HttpResponse<Blob>) => {
      var a = document.createElement('a'); //Create <a>
      a.href = window.URL.createObjectURL(res.body!);
      a.download = res.headers.get('Content-Disposition')?.split('filename=')[1]!;
      await Promise.all(ids.map(id => this.registerAction(1, id)))
        .then(() => {
          let s = {...this.eventMessages["actions.-1"].success};
          s.detail += ` ${a.download}`;
          a.click();
          return s;
        })
        .catch(() => (this.eventMessages["actions.-1"].error))
    }).catch(() => (this.eventMessages["actions.-1"].error))
  };

  private async setConform(document: any, according: boolean): Promise<any>{
    return this.registerAction(3, document.id, {according})
    .then( _ => {
      document.actions.according.status = 'done';
      document.actions.according.done = true;
      document.actions.according.data.according = according ;
      return this.eventMessages["actions.3"].success;
    }).catch(() => (this.eventMessages["actions.3"].error))
  }

  private downloadSingleDocument(employeeDocument: DocumentProcessedResponse): Promise<any> {
      return this.getBase64Document(employeeDocument.id)
      .then(({ base64, name, extension }: Base64Document) => {
        var a = document.createElement('a'); //Create <a>
        a.href = `data:application/octet-stream;base64,${base64}`; //Image Base64 Goes here
        a.download = `${name}.${extension}`;
        return this.registerAction(1, employeeDocument.id)
          .then(() => name)
          .catch(() => (this.eventMessages["actions.1"].error))
        .then((name) => {
          let s = {...this.eventMessages["actions.1"].success};
          s.detail += ` ${name}`;
          a.click();
          return s;
        }).catch(() => (this.eventMessages["actions.1"].error))
      }).catch(() => (this.eventMessages["actions.1"].error))
  }


  private previewDocument(employeeDocument: DocumentProcessedResponse): Promise<any> {
    return this.getBase64Document(employeeDocument.id)
    .then(({ name, base64 }: Base64Document) => {
      return this.registerAction(2, employeeDocument.id)
        .then(() => {
          let s = {...this.eventMessages["actions.2"].success};
          s.detail += ` ${name}`;
          this.displayedDocument.next({name, url: `${base64}`})
          return s;
        }).catch(() => (this.eventMessages["actions.2"].error));
    }).catch(() => (this.eventMessages["actions.2"].error));
}

  private getBase64Document(id: any): Promise<Base64Document> {
    return this.queue.add(async _=> lastValueFrom(this.http.get<Base64Document>(`${environment.apiServerUrl}/documents/${id}/file-base-64?available=true`)));
  }

  private async registerAction(
    actionId: number,
    documentId: number,
    customProperties: any | undefined = {}
    ) {
      const documentAction = await this.getDocumentAction(actionId, documentId, customProperties);
      let response
      try {
        response = await lastValueFrom(
          this.http.post<any>(`${environment.apiServerUrl}/documents-actions`,documentAction)
        );
      } catch (e: any) {
        if (e?.status !== 409) throw e;
        response = await lastValueFrom(
          this.http.put<any>(`${environment.apiServerUrl}/documents-actions`, documentAction)
        );
    }
    return response;
  }

  private async getDocumentAction(
    actionId: number,
    documentId: number,
    customProperties: any | undefined
  ): Promise<any> {
    return {
      actionId,
      documentId,
      updatedByUserId: this.getEmployeeId(),
      device: await this.deviceService.getDevices(),
      ip: await this.ipService.geIp(),
      ...customProperties
    };
  }
}

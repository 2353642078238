import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Core
import { CoreModule } from '@app/core.module';

// Shared
import { SharedModule } from '@shared/shared.module';

// Components
import { LandingComponent } from '@pages/landing/landing.component';
import { DocumentsListComponent } from '@pages/documents-list/documents-list.component';

// SSO
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

// env
import { environment } from '@env/environment';
import { MessageService } from 'primeng/api';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  if(!environment.production) {
    console.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: `https://login.microsoftonline.com/${environment.tenantId}/`,
      redirectUri: window.location.origin + '/',
      navigateToLoginRequestUrl: true,
      // postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read'])
  if(environment.envName === 'dev') {
    protectedResourceMap.set(`${environment.apiServerUrl}/documents`,[`api://${environment.apiClientId}/portal_corp.all_component_endpoints`])
    protectedResourceMap.set(`${environment.apiServerUrl}/documents-actions`,[`api://${environment.apiClientId}/portal_corp.all_component_endpoints`])
    protectedResourceMap.set(`${environment.apiServerUrl}/documentsZip`,[`api://${environment.apiClientId}/portal_corp.all_component_endpoints`])
    protectedResourceMap.set(`${environment.apiServerUrl}/{id}/file-base-64`,[`api://${environment.apiClientId}/portal_corp.all_component_endpoints`])
  } else {
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read'])
    protectedResourceMap.set(`${environment.apiServerUrl}/documents`,[`api://${environment.apiClientId}/user.read_documents`])
    protectedResourceMap.set(`${environment.apiServerUrl}/documents-actions`,[`api://${environment.apiClientId}/user.read_documents`])
    protectedResourceMap.set(`${environment.apiServerUrl}/documentsZip`,[`api://${environment.apiClientId}/user.read_documents`])
    protectedResourceMap.set(`${environment.apiServerUrl}/{id}/file-base-64`,[`api://${environment.apiClientId}/user.read_documents`])
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/landing',
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    DocumentsListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    MsalModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MessageService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

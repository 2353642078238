// Core
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule} from '@angular/common/http';

// Traductions
import { TranslateModule } from '@ngx-translate/core';

// Components
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginUserComponent } from './components/login-user/login-user.component';

// Pipes
import { NoSanitizePipe } from './pipes/no-sanitize.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { ResolveStringObjectPipe } from './pipes/resolve-string-object.pipe';
import { CalendarLanguagePipe } from './pipes/calendar-language.pipe';
import { DivideNumberPipe } from './pipes/divide-number.pipe';
import { transformCellPipe } from './pipes/transform-cell.pipe';
import { NoCommaPipe } from './pipes/no-comma.pipe';
import { PositiveSymbolPipe } from './pipes/positive-symbol.pipe';

// Prime NG
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {SidebarModule} from 'primeng/sidebar';
import {ButtonModule} from 'primeng/button';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {MenuModule} from 'primeng/menu';
import {ToastModule} from 'primeng/toast';
import {SlideMenuModule} from 'primeng/slidemenu';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TableModule} from 'primeng/table';
import {AccordionModule} from 'primeng/accordion';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {InputTextModule} from 'primeng/inputtext';
import {InputNumberModule} from 'primeng/inputnumber';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {CheckboxModule} from 'primeng/checkbox';
import {InputMaskModule} from 'primeng/inputmask';
import {DialogModule} from 'primeng/dialog';
import {CardModule} from 'primeng/card';
import {SpeedDialModule} from 'primeng/speeddial';
import {SplitButtonModule} from 'primeng/splitbutton';
import { CastPipe } from './pipes/cast.pipe';


const appComponents = [
  HeaderComponent,
  FooterComponent,
  LoginUserComponent
];

const appModules = [
  CommonModule,
  ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
  FormsModule,
  RouterModule,
  TranslateModule,
  DropdownModule,
  CalendarModule,
  SidebarModule,
  ButtonModule,
  BreadcrumbModule,
  MenuModule,
  ToastModule,
  SlideMenuModule,
  HttpClientModule,
  ProgressSpinnerModule,
  TableModule,
  AccordionModule,
  ConfirmDialogModule,
  InputTextModule,
  InputNumberModule,
  RadioButtonModule,
  InputTextareaModule,
  CheckboxModule,
  InputMaskModule,
  DialogModule,
  CardModule,
  SpeedDialModule,
  SplitButtonModule
];

const appDirectivesPipes = [
  NoSanitizePipe,
  CustomDatePipe,
  ResolveStringObjectPipe,
  CalendarLanguagePipe,
  DivideNumberPipe,
  transformCellPipe,
  NoCommaPipe,
  PositiveSymbolPipe,
  CastPipe
];

@NgModule({
  imports: appModules,
  exports: [ ...appComponents, ...appDirectivesPipes, appModules ],
  declarations: [ ...appComponents, ...appDirectivesPipes ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }

import { Injectable } from '@angular/core';
import * as UAParser from 'ua-parser-js';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() { }

  async getDevices(): Promise<String | undefined> {
    return new UAParser(navigator.userAgent).getResult().device.type;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
  transform(value: string, args: string): any {
    if (!value) {
      return value;
    }
    return moment(value, 'DDMMYYYYHHmmss').format(args);
  }
}

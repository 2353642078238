import { DocumentProcessedResponse, KNOWN_ACTIONS } from '@schemas/document.interface';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, Subscription, of, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

// service
import { DocumentService } from '@app/services/documents/documents.service';
import { Dialog } from 'primeng/dialog';
@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
  providers: [ConfirmationService, MessageService, PrimeNGConfig],
})
export class DocumentsListComponent implements OnInit {
  @ViewChild('dt') dt: Table | undefined;

  displayDocument = false;
  public readonly displayedDocument$ =  this.documentService.displayedDocument$.pipe(
    tap(() => {
      this.displayDocument = true;
    })
  );
  subscription: Subscription | undefined;

  DocumentProcessedResponse!: DocumentProcessedResponse;
  applications: any = [];
  selectedApplications!: any;
  loading: boolean = true;
  filteredType: any;

  // documentStatus = [
  //   {i18Key: 'seen'},
  //   {i18Key: 'blocked'},
  //   {i18Key: 'pending'}
  // ];

  constructor(
    public translate: TranslateService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private primeNGConfig: PrimeNGConfig,
    public documentService: DocumentService,
    private route: ActivatedRoute
  ) {
      this.route.queryParams.subscribe(params => {
        this.filteredType = params['type'].toLowerCase();
      });
    }

  ngOnInit(): void {
    this.loading = true;
    window.scrollTo(0, 0);
    this.subscription = this.translate.stream('primeng').subscribe((data) => {
      this.primeNGConfig.setTranslation(data);
    });

    this.documentService
      .getLoggedInEmployeeDocuments()
      .subscribe({
        next: (res: any) => {
          this.applications = res;
          if (this.filteredType) {
            this.applications = this.applications.filter((document: any) => document.documentType.toLowerCase() === this.filteredType);
          }
          this.loading = false;
        },
        error: (error: any) => {
          this.loading = false;
        },
      });

      this.documentService.isLoading$.subscribe(isLoading => this.loading = isLoading);
      this.documentService.events$.subscribe(event => this.messageService.add(event));
  }

  //#region documents
  downloadMultipleDocuments() {
    this.documentService.dispatchAction(-1, ...this.selectedApplications.map((document:any) => document.id));
  }
  //#endregion

  //#region datatables
  applyFilterGlobal($event: any, stringVal: string) {
    this.dt!.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  //#endregion
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cellTransform' })
export class transformCellPipe implements PipeTransform {
  transform(value: any, args: any): any {
    // Esto funciona de la siguiente manera
    // le pasas como argumento un array, el primer valor te dice que valor quieres substituir 
    // el segundo valor es por lo quelo quieres subsitituir
    // por ejemplo quieres substituir el -1 por un guión, en el html pones cellTransform : [-1, '-']
    if(value === args[0] ) {
      return args[1];
    } else {
      return value;
    }
  }
}
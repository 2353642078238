<p-toast></p-toast>
<div class="c-listado-low">
    <div class="container-90-per">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="c-listado-low__title">
                    {{"documents.list.title" | translate}}</h1>
                  <p-table #dt
                    [value]="applications"
                    [paginator]="true"
                    [rows]="10"
                    [rowsPerPageOptions]="[10,25,50]"
                    [showCurrentPageReport]="true"
                    [(selection)]="selectedApplications"
                    [rowHover]="true"
                    dataKey="id"
                    currentPageReportTemplate="{{'documents.list.tableNavigator.showing' | translate}} {first} {{'documents.list.tableNavigator.to' | translate}} {last} {{'documents.list.tableNavigator.of' | translate}} {totalRecords} {{'documents.list.tableNavigator.entries' | translate}}"
                    [loading]="loading"
                    [globalFilterFields]="['companyName', 'documentType', '_creationDateAsFormatString', 'creationMonthAndYear']"
                    [breakpoint]="'992px'"
                    [tableStyle]="{'min-width': '100%'}"
                    responsiveLayout="stack"
                  >
                    <ng-template pTemplate="caption">
                        <div class="flex">
                            <span class="p-input-icon-left ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText class="w-100" type="text" (input)="applyFilterGlobal($event, 'contains')" placeholder="{{'searchKey' | translate}}" />
                              </span>
                              <button *ngIf="selectedApplications&&selectedApplications.length > 0" (click)="downloadMultipleDocuments()"  pButton pRipple type="button"
                                  class="p-button--primary p-button-rounded c-p-l-1">
                                <i class="pi pi-download"></i>
                                &nbsp;
                                {{'documents.list.tableCaption.dowloadBtn' | translate}}
                              </button>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" let-application>
                        <tr>
                            <th>
                              {{"documents.list.tableHeaders.select" | translate}}
                            </th>
                            <th pSortableColumn="companyName">
                              {{"documents.list.tableHeaders.companyName" | translate}}
                              <p-sortIcon field="companyName"></p-sortIcon>
                            </th>
                            <th pSortableColumn="documentType">
                              {{"documents.list.tableHeaders.documentType" | translate}}
                              <p-sortIcon type="text" field="documentType"></p-sortIcon>
                            </th>
                            <th pSortableColumn="creationDate" class="d-none">
                              {{"documents.list.tableHeaders.creationDate" | translate}}
                              <p-sortIcon  type="date" field="creationDate"></p-sortIcon>
                            </th>
                            <th pSortableColumn="creationMonthAndYear">
                              {{"documents.list.tableHeaders.monthAndYear" | translate}}
                              <p-sortIcon  type="date" field="creationMonthAndYear"></p-sortIcon>
                            </th>
                            <th>
                              {{"documents.list.tableHeaders.seen" | translate}}
                            </th>
                            <th>
                              {{"documents.list.tableHeaders.according" | translate}}
                            </th>
                            <th>
                              {{"documents.list.tableHeaders.action" | translate}}
                            </th>
                        </tr>
                        <tr>
                            <th>
                              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th>
                                <p-columnFilter type="text" field="companyName"></p-columnFilter>
                            </th>
                            <th>
                                <p-columnFilter type="text" field="documentType"></p-columnFilter>
                            </th>
                            <th class="d-none">
                              <p-columnFilter type="date" field="creationDate" appendTo="dt">
                                <ng-template pTemplate="filter" let-filter="filterCallback">
                                  <p-calendar appendTo="body" dateFormat="dd/mm/yy" (onSelect)="filter($event)"></p-calendar>
                                </ng-template>
                              </p-columnFilter>
                            </th>
                            <th>
                                <p-columnFilter type="text" field="creationMonthAndYear"></p-columnFilter>
                            </th>
                            <th>
                              <div class="flex justify-content-center align-items-center">
                                <p-columnFilter class="flex justify-content-center align-items-center" type="boolean" field="isSeen"></p-columnFilter>

                              </div>
                            </th>
                            <th>
                              <div class="flex justify-content-center align-items-center">
                                <p-columnFilter class="flex justify-content-center align-items-center" type="boolean" field="actions.according.data.according"></p-columnFilter>
                              </div>
                            </th>
                            <th> </th>
                        </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-application let-expanded="expanded">
                        <tr *ngIf="application | cast:DocumentProcessedResponse as document">
                            <td>
                              <span class="p-column-title">{{"documents.list.tableHeaders.select" | translate}}</span>
                              <p-tableCheckbox [value]="document"></p-tableCheckbox>
                            </td>
                            <td>
                              <span class="p-column-title">{{"documents.list.tableHeaders.companyName" | translate}}</span>
                              <a>{{document.companyName}}</a>
                            </td>
                            <td>
                              <span class="p-column-title">{{"documents.list.tableHeaders.documentType" | translate}}</span>
                              <a class="text-end">{{document.documentType}}</a>
                            </td>
                            <td class="d-none">
                              <span class="p-column-title">{{"documents.list.tableHeaders.creationDate" | translate}}</span>
                              <a>
                                  {{ document.creationDate | date:'dd/MM/yyyy'}}
                              </a>
                            </td>
                            <td>
                              <span class="p-column-title">{{"documents.list.tableHeaders.monthAndYear" | translate}}</span>
                              <a>
                                  {{ document.creationMonthAndYear }}
                              </a>
                            </td>
                            <td>
                              <span class="p-column-title">{{"documents.list.tableHeaders.seen" | translate}}</span>
                              <button
                                class="m-1"
                                pButton
                                pRipple
                                type="button"
                                icon="pi pi-eye"
                                disabled="true"
                                [ngClass]="{
                                  'p-button--disabledGreen': document.isSeen,
                                  'p-button--disabledRed': document.isNextDocumentToSee && !document.isSeen
                                }"
                              ></button>
                            </td>
                            <td>
                              <span class="p-column-title">{{"documents.list.tableHeaders.according" | translate}}</span>
                              <div class="row" >
                                <ng-container *ngTemplateOutlet="accordingButton; context: {
                                  $implicit: {
                                    status: document.actions.according.status,
                                    according: document.actions.according.data?.according??false
                                  }
                                }">
                                </ng-container>
                              <ng-template #accordingButton let-accord>
                                <div class="d-flex flex-row flex-nowrap" *ngIf="accord.status !== 'unavailable'">
                                  <button
                                    class="m-1"
                                    pButton
                                    pRipple
                                    type="button"
                                    icon="pi pi-check"
                                    [disabled]="accord.status !== 'pending'"
                                    [ngClass]="{
                                      'p-button--disabled': accord.status !== 'done',
                                      'p-button--disabledGreen':  accord.status === 'done' && accord.according
                                    }"
                                    (click)="documentService.dispatchAction(3, document, true)"
                                    ></button>
                                  <button
                                    class="m-1"
                                    pButton
                                    pRipple
                                    type="button"
                                    icon="pi pi-times"
                                    [disabled]="accord.status !== 'pending'"
                                    [ngClass]="{
                                      'p-button--disabled': accord.status !== 'done',
                                      'p-button--disabledRed': accord.status === 'done' && !accord.according
                                    }"
                                    (click)="documentService.dispatchAction(3, document, false)"
                                    ></button>
                                </div>
                              </ng-template>
                              </div>
                            </td>
                            <td>
                              <span class="p-column-title">{{"documents.list.tableHeaders.action" | translate}}</span>
                              <div class="relative w-100 d-flex flex-row justify-content-end  justify-content-lg-center">
                                <button pButton #button [disabled]="!(document.isSeen||document.isNextDocumentToSee)" type="button" icon="pi pi-cog" class="p-button--primary p-button-rounded" (click)="menu.toggle($event)"></button>
                                <div style="position: relative;display: none;">
                                  <div #myDiv style="position: relative; width: 50px;" >
                                    <p-menu #menu [popup]="true" [model]="document.menuActions" appendTo="body">
                                    </p-menu>
                                  </div>
                                </div>
                              </div>
                            </td>
                        </tr>
                      </ng-template>
                    </p-table>
                    <p-toast position="top-left" key="tl"></p-toast>
            </div>
        </div>
    </div>
</div>


<ng-container
*ngIf="displayedDocument$ | async as document"
>
  <p-dialog #dialog
      class="preview-document-dialog"
      [style]="{width: '95vw', height: '95vh'}"
      [header]="document.name"
      [maximizable]="true"
      [draggable]="false"
      [(visible)]="displayDocument"
      [modal]="true"
      [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    >
    <ngx-extended-pdf-viewer
      [base64Src]="document.url"
      [height]="'100%'"
      [useBrowserLocale]="true"
      [textLayer]="true"
      [showEditor]="false"
      [showHandToolButton]="true"
      [showOpenFileButton]="false"
      [showUnverifiedSignatures]="true"
      >
    </ngx-extended-pdf-viewer>
  </p-dialog>
</ng-container>


import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { filter, firstValueFrom, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
})
export class AppComponent implements OnInit {
  isIframe = false;
  private readonly destroying$ = new Subject<void>();

  constructor(
    private msalAuthService: MsalService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
    this.setUpGoogleAnalytics();
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.setActiveAccountIfExists();
    this.msalAuthService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this.destroying$),
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        ),
      )
      .subscribe(() => {
        if (this.msalAuthService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        takeUntil(this.destroying$),
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
      )
      .subscribe(() => {
        this.setActiveAccountIfExists();
      });
  }

  setActiveAccountIfExists() {
    const activeAccount = this.msalAuthService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.msalAuthService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalAuthService.instance.getAllAccounts();
      this.msalAuthService.instance.setActiveAccount(accounts[0]);
    }
  }

  setUpGoogleAnalytics() {
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd && this.msalAuthService.instance)
        gtag('config', 'G-M4QENHFKMJ', {
          user_id:
            this.msalAuthService.instance
              .getActiveAccount()!
              .username.split('@', 1)
              .shift() ?? '',
        });
    });
  }
}

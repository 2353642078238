import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  constructor(private http: HttpClient) { }

  async geIp(): Promise<string | undefined> {
    return lastValueFrom(
      this.http.get<IP>('https://api64.ipify.org/?format=json')
      .pipe(map((response: IP) => response.ip))
    ).catch( () => undefined );
  }
}

interface IP {
  ip: string;
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentMonth: any;
  date!: string;
  hour!: string;
  min!: number;
  myClass: string;
  visibleDots = true;
  timeStamp = 1000;
  private diasSemana!: string[];

  mySetInterval: any;

  constructor(public translate: TranslateService) {
    this.myClass = 'c-footer__hour--hidden';
  }

  ngOnInit() {
    this.translate.stream('weekDays').subscribe((res: any) => {
      this.diasSemana = new Array(res['sun'], res['mon'], res['tue'], res['wed'], res['thu'], res['fri'], res['sat']);
    });

    this.mySetInterval = setInterval(() => {
      this.newDate();
      this.toggleClass();
    }, this.timeStamp);
  }


  // obtencion del dia y la hora
  newDate(month?: string) {
    const thisDate = new Date();
    if(!month) {
      this.currentMonth = String(thisDate.getMonth() + 1);
    } else {
      this.currentMonth = ''
    }
    this.currentMonth.length === 1 ? this.currentMonth = '0' + this.currentMonth : this.currentMonth = this.currentMonth;
    this.date = (this.diasSemana[ thisDate.getDay() ] + ', ' + thisDate.getDate() + '/' + this.currentMonth + '/' + thisDate.getFullYear() + ' · ');
    this.hour = ' ' + thisDate.getHours();
    this.min = thisDate.getMinutes();
  }

  // Visible o no los puntos
  toggleClass() {
    if (this.visibleDots) {
      this.myClass = 'c-footer__hour--visible';
      this.visibleDots = false;
    } else {
      this.myClass = 'c-footer__hour--hidden';
      this.visibleDots = true;
    }
  }

}
